import * as React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export function ProjectsDollarGeneral() {
    return <StaticImage src="../../images/projects-dollargeneral.jpg" alt="" />
}

export function ProjectsSanAntonio() {
    return <StaticImage src="../../images/projects-sanantonio.jpg" alt="" />
}

export function ProjectsResidenceInn() {
    return <StaticImage src="../../images/projects-residenceinn.jpg" alt="" />
}

export function ProjectsSpringhillSuites() {
    return <StaticImage src="../../images/projects-springhillsuites.jpg" alt="" />
}

export function ProjectsAarons() {
    return <StaticImage src="../../images/projects-aarons.jpg" alt="" />
}

export function ProjectsBedBathBeyond() {
    return <StaticImage src="../../images/projects-bedbathbeyond.jpg" alt="" />
}


const Container = styled.div`
    padding: 5rem 1.5rem;
    font-family: "BS";
    letter-spacing: 0.01rem;
    text-align: center;

    h2 {
        font-weight: 500;
        font-size: 1.5rem;
        margin-bottom: .75rem;
    }

    p {
        font-size: .9rem;
    }

    a {
        background-color: var(--primary);
        padding: .45rem 1.25rem;
        margin-top: 1.5rem;
        border-radius: 12px;
        font-size: .7rem;
        font-family: "BS";
        font-weight: 500;
        letter-spacing: 0.05rem;
        color: var(--black);
        text-transform: uppercase;
    }
`

const ProjectsGallery = styled.div`

    margin: 3rem 0rem;

    @media (min-width: 768px) {
        margin: 3rem auto;
        max-width: 1200px;
    }

`

const ProjectsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    width: 100%;

    img {
        filter: saturate(0);
        padding-bottom: .5rem;
        transition: filter .6s ease;

        &:hover {
            filter: saturate(0.98);
            transition: filter .6s ease;
        }
    }

    div {

        text-align: center;
        font-size: 1rem;

        span {
            font-weight: 500;
            font-size: 1.2rem;
        }

        @media (min-width: 768px) {
        text-align: left;
        font-size: .8rem;

        span {
            font-weight: 500;
            font-size: .9rem;
        }
        }
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2rem;
    }

    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const Projects = () => (
    <Container>
        <h2>Featured Projects</h2>
        <p>Select projects from our expansive portfolio</p>
        <ProjectsGallery>
            <ProjectsGrid>
                <div>
                    <ProjectsResidenceInn />
                    <span>Residence Inn by Marriott</span><br/>
                    Jacksonville, FL
                </div>
                <div>
                    <ProjectsAarons />
                    <span>Aaron's</span><br/>
                    Toledo, OH
                </div>
                <div>
                    <ProjectsSanAntonio />
                    <span>360 Multi-family Unit</span><br/>
                    San Antonio, TX
                </div>
                <div>
                    <ProjectsDollarGeneral />
                    <span>Dollar General</span><br/>
                    Jacksonville, FL
                </div>
                <div>
                    <ProjectsSpringhillSuites />
                    <span>SpringHill Suites by Marriott</span><br/>
                    Jacksonville, FL
                </div>
                <div>
                    <ProjectsBedBathBeyond />
                    <span>Bed Bath & Beyond</span><br/>
                    Kenosha, WI
                </div>
            </ProjectsGrid>
        </ProjectsGallery>
        <Link to="/projects/">View More Projects</Link>
    </Container>
)

export default Projects
