import * as React from "react"
import styled from "@emotion/styled"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StaticImage } from "gatsby-plugin-image"

export function HeroImage2() {
    return <StaticImage src="../../images/hh-framing.jpg" alt="" />
}

export function HeroImage3() {
    return <StaticImage src="../../images/hh-pool.jpg" alt="" />
}

const Contain = styled.div`
    overflow: hidden;
`

const HeroOverlay = styled.div`
    position: absolute;
    top: calc(50% - 125px);
    right: 0;
    background: var(--white);
    width: 45vw;
    padding: 2rem 1rem;
    font-family: "BS";
    display: none;

    @media (min-width: 800px) {
        display: block;
    }

    @media (min-width: 1490px) {
        width: 40vw;
        padding: 2rem 3rem;
    }

    h1 {
        font-size: 1.5rem;
        font-weight: 500;
        color: var(--gray);
        margin-bottom: 1rem;

        span {
            color: var(--silver);
        }

        @media (min-width: 1440px) {
            font-size: 1.8rem;
        }
    }

    p {
        font-weight: 400;
        font-size: .8rem;
        width: 31vw;
        line-height: 140%;
        color: #252525;

        @media (min-width: 1490px) {
            width: 20vw;
            font-size: 1rem;
        }
    }
`

const Slide1 = styled.div`
    position: relative;
`
const Slide2 = styled.div`
    position: relative;
`
const Slide3 = styled.div`
    position: relative;
`
const Slide4 = styled.div`
    position: relative;
`
export default function SimpleSlider() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 5500,
      slidesToScroll: 1
    };
    return (
        <Contain>
            <Slider {...settings}>
                <Slide1>
                    <HeroImage3 />
                    <HeroOverlay>
                        <h1>
                            <span>Diversified services.</span><br/>
                            Unvarying quality.
                        </h1>
                        <p>
                            Civil, structural and mechanical professional engineers on staff to assist with design review, value engineering, and problem solving.
                        </p>
                    </HeroOverlay>
                </Slide1>
                <Slide2>
                    <HeroImage2 />
                    <HeroOverlay>
                        <h1>
                            <span>Diversified services.</span><br/>
                            Unvarying quality.
                        </h1>
                        <p>
                        Orion self-performs plumbing, cabinets and countertops (fabricated in Jacksonville, FL), rough carpentry, metal studs, insulation, drywall and paint.
                        </p>
                    </HeroOverlay>
                </Slide2>
            </Slider>
        </Contain>
    );
  }  
