import * as React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import MultiFamilyIcon from "../../images/icon-multifamily.inline.svg"
import HospitalityIcon from "../../images/icon-hospitality.inline.svg"
import CommercialIcon from "../../images/icon-commercial.inline.svg"
import RenovationIcon from "../../images/icon-renovation.inline.svg"

export function ServicesPreConstruction() {
    return <StaticImage src="../../images/services-preconstruction.jpg" alt="" />
}

export function ServicesConstruction() {
    return <StaticImage src="../../images/services-construction.jpg" alt="" />
}

export function ServicesPostConstruction() {
    return <StaticImage src="../../images/services-postconstruction.jpg" alt="" />
}

export function LogoSpringhill() {
    return <StaticImage src="../../images/logo-springhill_suites.png" alt="" />
}

export function LogoResidenceInn() {
    return <StaticImage src="../../images/logo-residence_inn.png" alt="" />
}

export function LogoCourtyard() {
    return <StaticImage src="../../images/logo-courtyard.png" alt="" />
}

export function LogoBedBath() {
    return <StaticImage src="../../images/logo-bedbathbeyond.png" alt="" />
}

export function LogoBigLots() {
    return <StaticImage src="../../images/logo-biglots.png" alt="" />
}

export function LogoUsNavy() {
    return <StaticImage src="../../images/logo-usnavy.png" alt="" />
}


const Container = styled.div`
    padding: 5rem 0;
    background: rgba(0,0,0,0.025);
    font-family: "BS";
    letter-spacing: 0.01rem;
`

const ServicesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 6rem;

    h2 {
        font-weight: 500;
        font-size: 1.5rem;
        margin-bottom: 4rem;
    }

    padding: 0 1.5rem;

`

const ProjectsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
        font-weight: 500;
        font-size: 1.5rem;
        margin-bottom: .75rem;
    }

    p {
        font-size: 1rem;
    }

    padding: 0 1.5rem;

`

const GridContainer = styled.div`
    margin-top: 4rem;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    overflow: hidden;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`

const GridItem = styled.div`
    font-weight: 500;
    text-transform: uppercase;
    font-size: .8rem;
    letter-spacing: 0.025rem;
    color: var(--gray);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    padding-bottom: 3rem;

    a {
        background-color: var(--primary);
        padding: .45rem 1.25rem;
        margin-top: 1.5rem;
        border-radius: 12px;
        font-size: .7rem;
        font-family: "BS";
        font-weight: 500;
        letter-spacing: 0.05rem;
        color: var(--black);
    }

    &:last-child {
        border-right: 0;
    }

    span {
        margin: 1rem 0 0;
    }

    svg {
        height: 60px;
        width: auto;
        fill: rgba(0,0,0,0.4);

        @media (max-width: 768px) {
            height: 80px;
        }
    }

    @media (min-width: 768px) {
        border-right: 1px solid rgba(0,0,0,0.25);
        border-bottom: 0;
        padding-bottom: 1rem;
    }
`

const ClientsContainer = styled.div`
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
        font-weight: 500;
        font-size: 1.5rem;
        margin: 0 4rem 4rem;
    }
`

const LogoGrid = styled.div`
    
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 3rem;
    padding: 0 2rem;

    div {
        /* display: grid; */
        /* display: block; */
    }

    img {
        max-height: 45px;
        width: auto;
        margin: 0 auto;
        /* object-fit: contain; */

        @media (max-width: 768px) {
            max-height: 50px;
            max-width: 340px;
        }

        @media (min-width: 1440px) {
            max-height: 60px;
            max-width: 390px;
        }
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 5rem;
    }
`

const ServicesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-column-gap: 1.5rem;
    /* margin: 0 3rem; */

    img {
        border-bottom: 3px solid var(--primary);
    }

    h3 {
        font-weight: 500;
        font-size: 1.15rem;
        margin: 2rem 1.5rem 0;
        text-align: left;
    }

    p {
        font-size: 1rem;
        color: #333;
        padding: 1rem 1.5rem 3rem;
        text-align: left;

        @media (min-width: 768px) {
            /* font-size: 14px; */
        }
    }

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2rem;
        max-width: 1240px;
        margin: 0 auto;
    }
`

const PreConstruction = styled.div`
    box-shadow: 0 3px 15px 3px rgb(0 0 0 / 7%);
`

const Construction = styled.div`
    box-shadow: 0 3px 15px 3px rgb(0 0 0 / 7%);
    
`
const PostConstruction = styled.div`
    box-shadow: 0 3px 15px 3px rgb(0 0 0 / 7%);
`

const Services = () => (
    <Container>
        <ServicesContainer>
            <h2>Orion is with you every step of the way</h2>
            <ServicesGrid>
                <PreConstruction>
                    <ServicesPreConstruction />
                    <h3>Pre-Construction</h3>
                    <p>We keep an eye on budgets to ensure the lowest costs,  stay on top of schedules for timely delivery and work closely with the design team to create your vision.</p>
                </PreConstruction>
                <Construction>
                    <ServicesConstruction />
                    <h3>Construction</h3>
                    <p>As a vertically integrated company we are the sub-contractors. From sequence to production and quality control, we stay focused on the goal.</p>
                </Construction>
                <PostConstruction>
                    <ServicesPostConstruction />
                    <h3>Post-Construction</h3>
                    <p>Includes site cleanup, systems training, final inspections and move-in coordination. After completion, we have a final walkthrough to ensure your complete satisfaction.</p>
                </PostConstruction>
            </ServicesGrid>
        </ServicesContainer>  
        <ProjectsContainer>
                <h2>Delivering value through turn-key solutions</h2>
                <p>Founded with the desire to stay customer focused and connected to each project.</p>
            <GridContainer>
                <GridItem>
                    <MultiFamilyIcon/>
                    <span>Multi-family</span>
                    <Link to="/projects/multifamily/">View Projects</Link>
                </GridItem>
                <GridItem>
                    <HospitalityIcon/>
                    <span>Hospitality</span>
                    <Link to="/projects/hospitality/">View Projects</Link>
                </GridItem>
                <GridItem>
                    <CommercialIcon/>
                    <span>Commercial</span>
                    <Link to="/projects/commercial/">View Projects</Link>
                </GridItem>
                <GridItem>
                    <RenovationIcon/>
                    <span>Renovation</span>
                    <Link to="/projects/renovations/">View Projects</Link>
                </GridItem>
            </GridContainer>
        </ProjectsContainer>
        <ClientsContainer>
            <h2>Trusted by innovators and industry leaders</h2>
            <LogoGrid>
                <div><LogoSpringhill /></div>
                <div><LogoBedBath /></div>
                <div><LogoResidenceInn /></div>
                <div><LogoBigLots /></div>
                <div><LogoCourtyard /></div>
                <div className="limit-height"><LogoUsNavy /></div>
            </LogoGrid>
        </ClientsContainer>
    </Container>
)

export default Services
