import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/index/hero"
import Services from "../components/index/services"
import Projects from "../components/index/projects"


const IndexPage = () => (
  <Layout>
    <Seo title="Diversified services. Unvarying quality." />
    <Hero />
      <Services/>
      <Projects/>
  </Layout>
)

export default IndexPage
